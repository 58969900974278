export const ZOOM_BREAKPOINT = {
  PLACE: 3,
  DONG: 5,
  GU: 8,
  CITY: 9,
};

export const DEFAULT_ZOOM_LEVEL = 2;
export const MIN_ZOOM_LEVEL = 1;
export const MAX_ZOOM_LEVEL = 10;

// 지도타입버튼이 축소(및 가로로 펼쳐짐)되는 window height
export const MAP_TYPE_BUTTON_BREAKPOINT = 716;
// 거리재기도구버튼이 축소(및 가로로 펼쳐짐)되는 window height
export const MEASURING_TOOL_BUTTON_BREAKPOINT = 820;
