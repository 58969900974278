import {
  isRegionType,
  isRoadType,
  LocalDocument,
  LocalDocumentByAddress,
  SeachHistory,
} from 'apis/search';
import { buildQueryString } from 'utils/urlUtil';
import mapApis from 'apis/map';
import { COMMON_APP_ROUTES } from 'constants/routes';

/**
 * Lnadbook 3.0
 * @description AI건축분석 서비스 지역
 * 서비스 지역: 서울, 경기, 인천, 부산, (대구, 광주, 대전, 울산, 제주 2022.10 추가)
 * parcel정보가 존재하는 영역
 */
export const SERVICE_REGION = [
  '서울',
  '경기',
  '인천',
  '부산',
  '대구',
  '광주',
  '대전',
  '울산',
  '제주',
];

/**
 * 경매 서비스지역
 */
export const AUCTION_SERVICE_REGION = [
  '서울',
  '경기',
  '인천',
  '부산',
  '대구',
  '광주',
  '대전',
  '울산',
  '제주',
];

/**
 * 베이직 신축분석 지원범위
 */
export const BASIC_SERVICE_REGION = [
  '서울',
  '경기',
  '인천',
  '부산',
  '대구',
  '광주',
  '대전',
  '울산',
  '제주',
];

const DO_LEVEL_REGIONS = ['경기'];

export enum RegionLevel {
  'CITY' = 1,
  'DONG' = 2,
  'GU' = 3,
  'DETAIL' = 4,
}

/**
 * @example
 * getRegionDepth(documents) // ['서울', '성동구', '성수동']
 * getRegionDepth(documents) // ['서울', '성동구', undefined]
 * getRegionDepth(documents) // ['서울', '성동구', '성수동', undefined, '메쉬커피']
 * getRegionDepth(documents) // ['경기', '성남시 분당구', '운중동', undefined, '메쉬커피']
 */
export const getRegionDepth = (documents: LocalDocument) => {
  let depthArr: string[] = [];

  if (isRegionType(documents) || isRoadType(documents)) {
    const { region_1depth_name, region_2depth_name, region_3depth_name } =
      isRegionType(documents) ? documents.address : documents.road_address;

    depthArr = [region_1depth_name, region_2depth_name, region_3depth_name];
  } else {
    // case: LocalDocumentByKeyword
    const [
      region1DepthName,
      region2DepthName,
      region3DepthName,
      region4DepthName,
      region5DepthName,
    ] = documents.address_name.split(' ');
    const isDoLevel = DO_LEVEL_REGIONS.some(doName =>
      region1DepthName.startsWith(doName)
    );

    if (isDoLevel) {
      depthArr = [
        region1DepthName,
        `${region2DepthName} ${region3DepthName}`,
        region4DepthName,
        region5DepthName,
        documents.place_name,
      ];
    } else {
      depthArr = [
        region1DepthName,
        region2DepthName,
        region3DepthName,
        region4DepthName,
        documents.place_name,
      ];
    }
  }

  return depthArr;
};

/**
 * 주소의 레벨을 반환한다.
 * @example
 *   getRegionLevel('서울시')  // 1
 *   getRegionLevel('성동구')  // 2
 *   getRegionLevel('성수동1가')  // 3
 *   getRegionLevel('서울 성동구 성수동1가 668-11')  // 4
 */
export const getRegionLevel = (documents: LocalDocument): RegionLevel => {
  const addressType = ['REGION_ADDR', 'ROAD_ADDR'];
  const isAddress = addressType.includes(
    (documents as LocalDocumentByAddress)?.address_type
  );

  if (isAddress) {
    return 4;
  }

  return getRegionDepth(documents).slice(0, 4).filter(Boolean).length;
};

type ArticleQueryParams = {
  city: string;
  gu?: string;
  dong?: string;
  place?: string;
  longitude: number;
  latitude: number;
};

export const buildSpecificArticlePath = async (
  queryParams: ArticleQueryParams
) => {
  const articleListPath = buildArticleListPath(queryParams);
  const { data } = await mapApis.requestClickedPnu(
    queryParams.latitude,
    queryParams.longitude
  );

  const queryString = buildQueryString({
    pnu: data.id,
    zoomLevel: 2,
  });

  return [articleListPath, queryString].join('&');
};

export const buildArticleListPath = (queryParams: ArticleQueryParams) => {
  const queryString = buildQueryString(queryParams);
  return [COMMON_APP_ROUTES.SEARCH_MAP, queryString].join('?');
};
/**
 * 주소정보 기반으로 경로 반환
 * 상세 지번의 포함된 경우 분석으로 이동한다.
 * 검색탭에서 분석탭으로 이동하는 모든 케이스에서 /articless/basic으로 이동한다.
 */
export const buildPathByLocal = async (localDocument: LocalDocument) => {
  const regionLevel = getRegionLevel(localDocument);
  const [city, gu, dong, , place] = getRegionDepth(localDocument);

  const [longitude, latitude] = [
    Number(localDocument.x),
    Number(localDocument.y),
  ];
  const queryParams = {
    city,
    gu,
    dong,
    place,
    longitude,
    latitude,
    address_name: localDocument.address_name,
  };

  const articleListPath = buildArticleListPath(queryParams);

  try {
    if (regionLevel === RegionLevel.DETAIL) {
      return await buildSpecificArticlePath(queryParams);
    } else {
      return articleListPath;
    }
  } catch (e) {
    // 실패시 매물 리스트로 이동합니다.
    return articleListPath;
  }
};

/**
 * 서비스 지역인지 검증
 */
export const isServiceLocal = (document: LocalDocument) => {
  const [region_1depth_name] = getRegionDepth(document);

  return SERVICE_REGION.some(region => region_1depth_name.startsWith(region));
};

/**
 * 모든 검색 결과가 서비스 외 지역인지 확인
 */
export const checkNonServiceLocal = (list: LocalDocument[]) =>
  list.every(document => !isServiceLocal(document));

export const extractAddressFromSearchHistory = (
  searchHistory: SeachHistory
) => {
  const {
    address_name,
    region_1depth_name,
    region_2depth_name,
    region_3depth_name,
    place_name,
  } = searchHistory;
  const addressArr = address_name.split(' ');
  const city = region_1depth_name || addressArr[0];
  const gu = region_2depth_name || addressArr[1];
  const dong = region_3depth_name || addressArr[2];

  return {
    city,
    gu,
    dong,
    place: place_name,
  };
};
